

// import { autocomplete, getAlgoliaResults } from '@algolia/autocomplete-js'
// import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions'
// import { algoliasearch } from 'algoliasearch'

// import algoliasearch from 'algoliasearch'

export default {
	props: {
		placeholder: {
			type: String,
			default: `Search Lessons, Courses, and More`,
		}
	},
	data() {
		return {
			searchQuery: '',
		}
	},
	methods: {
		doSearch(){
			if(this.searchQuery) location.href = `/search/?q=${this.searchQuery}`
		},
	},
	created(){

			// const appId = 'D96IXX9O9V';
			// const apiKey = 'b343960d038b6a72de641c58713f58f6';
			// const searchClient = algoliasearch(appId, apiKey);

			// function tfSuggestionPlugin(searchModel){
			// 	return createQuerySuggestionsPlugin({
			// 	  transformSource({ source, onTapAhead }) {
			// 	  	return {
			//         getItems({ query }) {
			//           return getAlgoliaResults({
			//             searchClient,
			//             queries: [
			//               {
			//                 indexName: `staging_truefire_${searchModel}_en`,
			//                 query,
			//               },
			//             ],
			//           });
			//         },
			//         getItemUrl({ item }) {
			//         	let url
		  //         	if(item.guid.includes('COURSE')) url = item.link
		  //       		else if(item.guid.includes('EDUCATOR')) url = `/e${item.authorid}`
			//           return url
			//         },
			// 				templates: {
			//           item({ item, html }) {
			//           	// let htmlStr = html`<a href="${item.link}" style="display:block;">
			// 	          //   	${item.title}
			// 	          //   </a>`
			//           	// if(item.guid.includes('COURSE')){
			// 	          // 	if(item.link.includes('.com')) item.link = item.link.substring(item.link.indexOf('.com') + 4)
			// 	          //   htmlStr = html`<a href="${item.link}" style="display:block;">
			// 	          //   	${item.title}
			// 	          //   </a>`;
			// 	          // } else if(item.guid.includes('EDUCATOR')){
			// 	          //   htmlStr = html`<a href="/e${item.authorid}" style="display:block;">
			// 	          //   	${item.title}
			// 	          //   </a>`;
			// 	          // }
			// 	          //  else if(item.guid.includes('ITEMJ')) {
			// 	          //   htmlStr = html`<a href="/j${item.id}" style="display:block;">
			// 	          //   	${item.title}
			// 	          //   </a>`;
			// 	          //  }
			// 	          return html`<a href="/search/?q=${encodeURIComponent(item.title)}" style="display:block;">
			//             	${item.title}
			// 	            </a>`
			//           },
			// 				},
			// 				onSelect({setIsOpen}){
			// 					setIsOpen(true)
			// 				}
			// 	  	}
			// 	  }
			// 	});
			// }

			// function setupAutocomplete(){

			// 	const coursesSuggestionPlugin = tfSuggestionPlugin('courses'),
			// 		jamsSuggestionPlugin = tfSuggestionPlugin('jams'),
			// 		educatorsSuggestionPlugin = tfSuggestionPlugin('educators')

			// 	autocomplete({
			// 		insights: true,
			// 		container: '#autocompleteContainer',
			// 		openOnFocus: true,
			// 		plugins: [coursesSuggestionPlugin, jamsSuggestionPlugin, educatorsSuggestionPlugin],
			// 		placeholder: 'Search Guitar Lessons, Courses, and More...',
			// 		onSubmit({state, event}){
			// 			if(state.query) location.href = `/search/?q=${encodeURIComponent(state.query)}`
			// 		},
			// 		reshape({sources, sourcesBySourceId, state}){
			// 			return sources.map((source, index) => {
			// 				return {
			// 					...source,
			// 					getItems(){
			// 						const items = source.getItems(),
			// 							topItems = [], bottomItems = []
			// 						for(let i = 0; i < items.length; i++){
			// 							items[i]['_highlightResult'].title.matchedWords.length ?
			// 								topItems.push(items[i]) :
			// 								bottomItems.push(items[i])
			// 						}
			// 						return [...topItems, ...bottomItems].slice(0, 5)
			// 					}
			// 				}
			// 			})
			// 		}
			// 	})

			// }

			// setTimeout(setupAutocomplete, 1000)

	}
}
