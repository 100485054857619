
	export default {
		data(){
			return {
				navs: [
					{
						items: [
							{
								label: `All Access`,
								url: '/all-access/upgrade/'
							},
							{
								label: `Sales`,
								url: '/sales-promos'
							},
							{
								label: `Most Popular`,
								url: '/search/?q=Most%20Popular'
							},
							{
								label: `Apps`,
								url: '/apps'
							},
							{
								label: `Free Downloads`,
								url: '/free-downloads'
							},
							{
								label: `Learning Paths`,
								url: '/learning-paths'
							},
							{
								label: `Educators`,
								url: '/educators'
							},
							{
								label: `Blog`,
								url: 'https://blog.truefire.com/'
							},
							{
								label: `Community`,
								url: '/community'
							}
						]
					},
					{
						label: `Tools`,
						items: [
							{
								label: `Guitar Chord Charts`,
								url: '/guitar-chord-charts'
							},
							{
								label: `Guitar Tuner`,
								url: '/guitar-tuner'
							},
							{
								label: `Metronome`,
								url: '/metronome'
							},
							{
								label: `Guitar Chord Finder`,
								url: '/guitar-chord-finder'
							},
							{
								label: `Practice Guide`,
								url: '/25-principles-perfect-practice'
							},
							{
								label: `Jam Tracks`,
								url: `/jam-packs`
							},
							{
								label: `Guitar Scales`,
								url: '/guitar-scales'
							},
							{
								label: `Chord Library`,
								url: '/guitar-chord-library'
							}
						]
					},
					{
						label: `Company`,
						items: [
							{
								label: `About`,
								url: '/about'
							},
							{
								label: `Affiliates`,
								url: '/affiliates'
							},
							{
								label: `Partnerships`,
								url: 'https://partnerwith.truefire.com/'
							},
							{
								label: `Press`,
								url: '/press'
							},
							{
								label: `Team`,
								url: '/team'
							},
							{
								label: `TrueFire Studios`,
								url: '/truefire-studios'
							},
							{
								label: `JamPlay`,
								url: '/search/?q=JamPlay'
							},
							{
								label: `ArtistWorks`,
								url: 'https://www.artistworks.com'
							}
						]
					},
					{
						label: `More`,
						items: [
							{
								label: `Gift Certificates`,
								url: `/gift-certificates`
							},
							{
								label: `Refer a Friend`,
								url: `/refer`
							},
							{
								label: `Merch`,
								url: `https://truefire.threadless.com/`
							},
							{
								label: `DVD Assets`,
								url: `/standard-dvd-assets`
							},
							{
								label: `Audio Lessons`,
								url: `/list.html?store=audio_lessons`
							},
							{
								label: `Cookie Preferences`,
								osano: true
								// url: `https://blog.truefire.com/guitar-gear/50-best-gifts-for-guitarists-2021/?_gl=1*1x226y6*_ga*MTU1MjcyMTcwNC4xNjk1MjQwMzgy*_ga_SLG7T6TCHX*MTY5NTM5ODc5OC4zMC4xLjE2OTUzOTk5NjAuMC4wLjA.`
							},
							{
								label: `Teach`,
								url: `https://form.typeform.com/to/wayd9VaV`
							},
							{
								label: `Contact`,
								url: `https://truefire.zendesk.com/hc/en-us/articles/200326536`
							}
						]
					}
				],
				social: [
					{
						icon: `facebook`,
						url: `https://facebook.com/TrueFire`
					},
					{
						icon: `twitter`,
						url: `https://twitter.com/TrueFireTV`
					},
					{
						icon: `youtube`,
						url: `https://youtube.com/TrueFireTV`
					},
					{
						icon: `instagram`,
						url: `https://instagram.com/TrueFireTV`
					}
				],
				bottom: [
					{label: `Terms`, url: `/terms`},
					{label: `Privacy`, url: `/privacy-policy`},
					{label: `Help`, url: `/help`},
					{label: `Guides`, url: `/help`},
					{label: `FAQ`, url: `https://truefire.zendesk.com/hc/en-us/categories/200018593`},
					{label: `Rescue`, url: `/rescue`},
				]
			}
		}
	}
