
	export default {
		data() {
			return {
				nav: [
					{
						label: 'Learn',
						hover: true,
						megaItems: [
							{
								label: 'Learning Paths',
								items: [
									{label: 'Beginner'},
									{label: 'Blues'},
									{label: 'Rock'},
									{label: 'Jazz'},
									{label: 'Fingerstyle'},
									{label: 'Country'},
									{label: 'Bass'}
								]
							},
							{
								label: 'Courses',
								items: [
									{label: 'New & Hot'},
									{label: 'Most Popular'},
									{label: 'Recommendations'},
									{label: 'Browse by Style'},
									{label: 'Browse by Level'},
									{label: 'Browse by Instrument'},
									{label: 'All Courses'}
								]
							},
							{
								label: 'Quick Links',
								items: [
									{label: 'Educators'},
									{label: 'Free lessons'},
									{label: 'Sales'},
									{label: 'Pre-Orders'}
								]
							}
						]
					},
					{
						label: 'Practice',
						hover: false,
						items: []
					},
					{
						label: 'Play',
						hover: false,
						items: []
					}
				]
			}
		},
		methods: {
		},
		async created() {
		}
	}
